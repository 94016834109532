.footerSignature {
  margin: 0 auto;
  font-family: "Helvetica Neue";
  font-weight: 600;
  font-size: 1rem;
}
.footerSignature a {
  text-decoration: none;
  color: black;
  margin: 0 5px;
}
.footerSignature a:hover {
  text-decoration: underline;
  animation-name: slidein;
}
.footerSignature img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

.alignBottom {
  margin-top: auto;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
