.proposals {
  display: flex;
  flex-direction: column;
}

.proposals > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.heading {
  display: inline-block;
  font-size: 2rem;
  margin: 0 !important;
}

.createProposalLink {
  padding: 0.9rem 1.5rem;
  background-color: rgba(41, 63, 215, 1);
  border-radius: 5px;
  border: 0 none;
  font-size: large;
  font-weight: normal;
  cursor: default;
}

.createProposalLink:hover {
  background-color: rgba(41, 63, 215, 0.9);
}

.proposalLink {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
